<template>
	<v-layout>
		<v-flex md8>
			<DatePicker
				:hideTopMargin="hideTopMargin"
				:hide-details="hideDetails"
				:readonly="readonly"
				:disabled="disabled"
				:loading="loading"
				:label="label"
				:id="id"
				:min-date="minDate"
				:placeholder="placeholder"
				v-model="date"
				v-on:change="emitValue()"
				:rules="[vrules.required(date, placeholder)]"
				:content-class="{
					required: !date,
				}"
				v-if="required"
			></DatePicker>
			<DatePicker
				v-else
				:hideTopMargin="hideTopMargin"
				:hide-details="hideDetails"
				:readonly="readonly"
				:disabled="disabled"
				:loading="loading"
				:label="label"
				:id="id"
				:min-date="minDate"
				:placeholder="placeholder"
				v-model="date"
				v-on:change="emitValue()"
			></DatePicker>
		</v-flex>
		<v-flex md4>
			<TimePicker
				class=""
				:readonly="readonly"
				:disabled="disabled"
				:loading="loading"
				:label="label"
				:id="id"
				placeholder="Time"
				v-model="time"
				format="HH:mm"
				v-on:change="emitValue()"
				:class="{
					required: !time,
					'mt-3': !hideTopMargin,
				}"
				:minute-interval="minuteInterval"
			></TimePicker>
		</v-flex>
	</v-layout>
</template>
<script>
import { format } from "date-fns";
import DatePicker from "@/view/components/DatePicker";
//import TimePicker from "@/view/components/TimePicker";
import TimePicker from "vue2-timepicker/src/vue-timepicker.vue";

export default {
	name: "date-time-input",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		minuteInterval: {
			type: Number,
			default: 1,
		},
		value: {
			type: String,
			default: null,
		},
		minDate: {
			type: [Date, String],
			default: null,
		},
		maxDate: {
			type: [Date, String],
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		hideTopMargin: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		DatePicker,
		TimePicker,
	},
	watch: {
		value() {
			this.init();
		},
	},
	methods: {
		init() {
			if (!this.value) {
				return false;
			}
			const dateTime = new Date(this.value);
			this.date = format(dateTime, "yyyy-MM-dd");
			this.time.HH = format(dateTime, "HH");
			this.time.mm = format(dateTime, "mm");
			this.emitValue();
		},
		emitValue() {
			if (!this.date) {
				return false;
			}

			const date = new Date(this.date);
			date.setHours(this.time.HH, this.time.mm);
			const formatted = format(date, "yyyy-MM-dd HH:mm");

			this.$emit("input", formatted);
			this.$emit("change", formatted);
		},
	},
	data() {
		return {
			date: null,
			time: {
				HH: "00",
				mm: "00",
			},
		};
	},
	mounted() {
		this.init();
	},
};
</script>
